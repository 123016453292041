@font-face {
    font-family: DIN-Bold;
    src        : url(./assets/fonts/DIN-Bold.otf);
}

.upc {
    text-transform: uppercase;
}

.pcmodal>.ant-modal-content {
    border-radius: 20px;
}

.momodal {
    padding         : 0;
    margin          : 0;
    position        : absolute;
    bottom          : 0;
    top             : unset;
    width           : 100% !important;
    max-width: 420px;
}

.pcmodal.momodal>.ant-modal-content {
    border-radius: 20px 20px 0 0 !important;

}